/* @import "~video-react/styles/scss/video-react"; */

.hero_image9{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../images/projectimages/plantss.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.case4{
    display:flex;
    flex-direction: column;
    margin-top: 5%;
    text-align: center;

}
.bigger{
    padding:0px;
    margin: 0px;
    width: 100% !important;
}
.hero_text9{
    color:white;
    text-align: center;
    padding:2%;
    padding-top: 10%;
}
.push{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: whitesmoke;
    text-align: left;
}
.push2{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: white;
    text-align: center;
    
}
.theProject1{
    margin-left: 25%;
    margin-right: 25%;  
}
.push2_text{
    padding:2%; 
    text-align: left;
}
.valueProp h3 h4{
    text-align: left;
}
/* .summary{
    background-color:gray;
    padding:2%;
} */
.questions{
    background-color:whitesmoke;
    padding:2%;
}
.researchPlan1{
    background-color:white;
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 25%;
    padding-right: 25%;
}
/* .researchPlan_text{
    text-align: left;
    margin-left: 20%;
    margin-right: 20%; 
} */
.tools{
    background-color:lightgrey;
    text-align: center;
    padding:2%;
}
.gridtextbox10{
    padding-top: 1%;
    font-size:  22px;
    margin-left: 40%;
    
    }
.questions_text{
    text-align: left;
    padding-left: 10%;
}
.gridtextbox2{
    align-items: center;
        padding-right: 10% !important;
        padding-top: 1%;
    }
.gridtextbox3{
    align-items: center;
        padding-left:10% !important;
        padding-top: 1%;
    }
.green{
    max-height: 300px;
    width: auto;
    padding:1%;
    
}
.green:hover{
   
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.greensContainer{
   padding-left: 10%;
}

.findings77{
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: whitesmoke;
   
}
.findings_text{
    text-align: left;
    margin-left: 25%;
    margin-right: 25%; 
}
.now10{
    align-items: center;
    padding-top:2%;
    background-color: white;
    padding-left: 10%;
    padding-right: 10%
}
.now_text{
    text-align: left;
    padding-left: 10% !important;
}
.gridtextbox7{
    align-items: center;
    padding-top: 1%;
    margin-left: 25%;
}
.gameContainer{
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
}
.game{
   height:400px;
    width: auto;
    padding:1%;

}
#go:hover{
    background-color: silver;
}
.looks{
    padding-top: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    background-color: white;

}
.care{
   width: 100%;
   height: auto;
}

.last{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../images/projectimages/plantss.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    text-align: center;
    padding-top: 4%;
}

.final{
    align-content: right;
    padding-top: 3rem;

}
.final_text{
    padding-left: 15%;
}
.wonder{
    background-color:whitesmoke;
  
}
.keep{
    color: white;
}
#projects_button{
    margin: 2%;
    background-color: #e7e7e7; color: black;
    font-size: 20px;
    border-radius: 4px;
    padding:1%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

}
#projects_button:hover{
    background-color: black; color: #e7e7e7;
    transform: translateY(-7px);
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  
}
.planta{
    width: 1005;
    height: 80vh;
    position: relative;
    padding-left: 20%;
}
.video{
    object-fit: cover;
    
}
