.about_container{
    /* margin:3rem 0; */
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.about_container img{
    /* border-radius: 100%; */
    /* border: 1px solid #444444; */
    margin-bottom: 1rem;
    width: 500px;
    height: 500px;
    object-fit: fill;
    background-color: whitesmoke;
    border-radius: 10px;
}
.about_container p{
   width: 500px;
    height: 500px;
    /* border: 2px solid #444444; */
     object-fit: scale-down;
     padding: 40px;
     background-color: whitesmoke;
     border-radius: 10px;
}

@media only screen and (max-width: 900px){
    .about_container{
        flex-direction: column;
    }
 
}
@media only screen and (max-width: 500px){
    .about_container{
        flex-direction: column;
    }
    .about_container p{
        padding-left: 15%;
        padding-right: 15%;
        padding-bottom: 3rem;
    }
 
}
.h4{
    display:flex;
    justify-content: center;
    text-align: center;
    color:black;
    
  
}
#talk{
    margin-bottom: 3%;
}
.skills{
    text-decoration: black;
    font-weight: bold;
    text-shadow: burlywood;
    font-size: 20px;
    color:black;

}




/* @media only screen and (max-width: 900px){
    .about_container p{
        /* max-width: 400px; */
    