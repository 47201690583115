@import url(https://fonts.googleapis.com/css2?family=Coming+Soon&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App{font-family: 'Lato', sans-serif;}
#root {
  --nav-color: #444444;
  --nav-color-hover:#000000;
  --nav-border-color:#444444;
  --light-color:#444444;
  --font-color:#FFFFFF;
  --font-color-hover:#070606;
  
}

.overlay{
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
}

.gallery{
  display: inline-block; 
 
}

.picMe{

  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-bottom: 240px;
  height: 500px;
    width: 500px;
    border: 3px solid #444444;
   
}
.string{
  display: flex;
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    z-index: 1;
    font-size: 44px;
    position: absolute; 
}


.body{
 position: relative;
}

.particles-bg-canvas-self{
  height: 100% !important;
  position:fixed;
  width:100%;
  z-index:-1;
  /* opacity: 60%; */

}





.contact_container{
    background-color: var(--nav-color);
    color:whitesmoke;
    padding: 1rem;
}

.contact_data_links{
    display: flex;
    justify-content: space-evenly;
}

.contact_data_links a {
    color: whitesmoke;
    text-decoration: none;
    text-transform: uppercase;
}

.contact_data_links a:hover{
    color: var(--light-color);
    background-color: azure;
    flex-direction: column;
}


.work{
  display: flex;
}

.projects{
  text-align: center;
}
.hi{
  text-align: center;
}

.img_g{

  object-fit: cover;
  height: 300px ;
  margin-top: -20%;
  width: 100%;
  border-radius: 20px;

}
.img_g1{

  object-fit: cover;
  height: 300px ;
  margin-top: -20% ;
  width: 100%;
  border-radius: 20px ;

}
.img_g1:hover{
  object-fit: cover;
  height: 300px ;
  margin-top: -20%;
  width: 100%;
  border-radius: 20px!important;;
}

.one{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: #e5e5e5!important;
  width: 20rem !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px !important;
  margin-top: 10rem;
  cursor: 'pointer'
}
/* .card2{
  height: 500px;
} */
.card-text{
  list-style-type:square;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.cardContainer{

  margin-bottom: 3rem;
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height:500px;
   
}

#git{
  color:black !important; 
  margin-bottom: 3rem;
}
#case{
  font-size: 1.2rem;
  border-radius: 10px;
  padding: 11px 1rem;
  /* margin: 0 0.5rem ; */
  width: 7.5rem;

}
.container-fluid{
  margin-top: 3rem;
  /* margin-bottom: 3rem; */
}
.card-title{
  margin: 0;
  margin-top: 1rem;
}
.across{
  display: flex;

}


.overlay{
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
  }
  
  .gallery{
    display: inline-block; 
   
  }
  
  .picMe{
  
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-bottom: 240px;
    height: 500px;
      width: 500px;
      border: 3px solid #444444;
     
  }
  .string{
    display: flex;
      align-items: center;
      justify-content: center;
      color: whitesmoke;
      z-index: 1;
      font-size: 44px;
      position: absolute; 
  }
  
  
  .body{
   position: relative;
  }
  
  .particles-bg-canvas-self{
    height: 100% !important;
    position:fixed;
    width:100%;
    z-index:-1;
    /* opacity: 60%; */
  
  }
  
  
  
  
  
.navi_container{
    background-color: var(--nav-color);
    color:white;
    /* padding-bottom: 100px; */
  }

  .navbar-light .navbar-nav .nav-link{
      color: var(--font-color)!important;
  }
  .navbar-light .navbar-brand{
    color: var(--font-color)!important;
}
.navbar-light .navbar-toggler-icon{
    -webkit-filter:invert(1);
            filter:invert(1);
}

.links a:hover{
    background-color:#A9A9A9!important;
    color:#070606;
}
.logo{

    height:60px;
    width: 60px;
    transition: all;
}
.logo:hover{
    /* background-image: url("../../images/blck-wsolve5.png"); */
    height:50px;
    width: 50px;
    -webkit-transform:scale(1.5,1.5);
            transform:scale(1.5,1.5);
    margin-right: 10px;

   
}
.small{
    padding-left: 15px;
}
.img{
    background-color:var(--nav-color) ;
    height:50px;
    width: 50px;
}
.links{
    margin: 0px 15px;
}
.nav_brand{
   color:whitesmoke; 

}.nav_brand:hover{
   color:#A9A9A9!important;
 }

.me{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 3rem; */
    color:black;
}
.period{
 margin-top: 3rem;
}



.about_container{
    /* margin:3rem 0; */
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.about_container img{
    /* border-radius: 100%; */
    /* border: 1px solid #444444; */
    margin-bottom: 1rem;
    width: 500px;
    height: 500px;
    object-fit: fill;
    background-color: whitesmoke;
    border-radius: 10px;
}
.about_container p{
   width: 500px;
    height: 500px;
    /* border: 2px solid #444444; */
     object-fit: scale-down;
     padding: 40px;
     background-color: whitesmoke;
     border-radius: 10px;
}

@media only screen and (max-width: 900px){
    .about_container{
        flex-direction: column;
    }
 
}
@media only screen and (max-width: 500px){
    .about_container{
        flex-direction: column;
    }
    .about_container p{
        padding-left: 15%;
        padding-right: 15%;
        padding-bottom: 3rem;
    }
 
}
.h4{
    display:flex;
    justify-content: center;
    text-align: center;
    color:black;
    
  
}
#talk{
    margin-bottom: 3%;
}
.skills{
    -webkit-text-decoration: black;
            text-decoration: black;
    font-weight: bold;
    text-shadow: burlywood;
    font-size: 20px;
    color:black;

}




/* @media only screen and (max-width: 900px){
    .about_container p{
        /* max-width: 400px; */
    
.whitespace{
    padding-top: 15%;
    text-align: center;
}
.white{
    text-align: center;
}
@-webkit-keyframes slide{
    0% {
        left: 100px;
        top: 0;
      }
      25% {
        left: 300px;
        top: 0;
      }
      50% {
        left: 600px;
        top: 100px;
      }
      75%{
          left:800px;
          top: 100px;
      }
      100% {
        left: 1500px;
        top: 0;
      }
    }
@keyframes slide{
    0% {
        left: 100px;
        top: 0;
      }
      25% {
        left: 300px;
        top: 0;
      }
      50% {
        left: 600px;
        top: 100px;
      }
      75%{
          left:800px;
          top: 100px;
      }
      100% {
        left: 1500px;
        top: 0;
      }
    }
    
.duckyContainer{
    position: relative;
    border-radius: 100px;
    height: 400px;
}
.duckyContainer:hover .ducky{
    -webkit-animation: slide 3s linear;
            animation: slide 3s linear;
}
.ducky{
    height:100px;
    width: auto;
    position: absolute;
}

/* .ducky:hover{
    animation: fly 60s linear infinite;
    pointer-events: none !important;
top: 0;
left: 0;
transform: translateX(-120%) translateY(-120%) rotateZ(0);
position: fixed;
animation-delay: 25s;
z-index: 999999;
}
@keyframes fly {

	98.001%, 0% {
                display: block;
		transform: translateX(-200%) translateY(100vh) rotateZ(0deg)
	}

	15% {
		transform: translateX(100vw) translateY(-100%) rotateZ(180deg)
	}

	15.001%, 18% {
		transform: translateX(100vw) translateY(-30%) rotateZ(0deg)
	}

	40% {
		transform: translateX(-200%) translateY(3vh) rotateZ(-180deg)
	}

	40.001%, 43% {
		transform: translateX(-200%) translateY(-100%) rotateZ(-180deg)
	}

	65% {
		transform: translateX(100vw) translateY(50vh) rotateZ(0deg)
	}

	65.001%, 68% {
		transform: translateX(20vw) translateY(-200%) rotateZ(180deg)
	}

	95% {
		transform: translateX(10vw) translateY(100vh) rotateZ(0deg)
	}
} */
.about55{
    display:flex;
    flex-direction: column;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-color: gainsboro;
    /* opacity:85%; */
    opacity: .8;
    color:black !important; 
    font-weight: bold;
    
    
}
.res{
    margin-top: 3rem;
}

.gridtextbox1{
align-items: left;
/* padding-left: 10% !important; */
font-size:  22px;

}
.gridtextbox2{
    align-items: right;
    /* padding-right: 10% !important; */
}


.info{
    align-content: left;
}
.resumeShape{
 z-index: 20;
    padding: 0,10%;
    width: -webkit-max-content;
    width: max-content;
    margin: 3rem;

}
@media only screen and (max-width: 500px){
    .about55{
        flex-direction: column;
        padding: 5%;
    }
    .resumeShape{
        padding: 5%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

.text {
	font-weight: bold;
}
.resLink{
    justify-content: center !important;
    text-align: center;
    padding-top:5%;
    margin-bottom: 0;

}


.name{
    color:whitesmoke;
    display: inline-block;
    z-index: 40;
    top:27%;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
.byes{
    /* position: fixed; */
    text-align: center;
    width: 100%;
    justify-content: center;
    background-repeat: no-repeat;
}







/* @import "~video-react/styles/scss/video-react"; */

.hero_image9{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/plantss.5208b7e4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.case4{
    display:flex;
    flex-direction: column;
    margin-top: 5%;
    text-align: center;

}
.bigger{
    padding:0px;
    margin: 0px;
    width: 100% !important;
}
.hero_text9{
    color:white;
    text-align: center;
    padding:2%;
    padding-top: 10%;
}
.push{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: whitesmoke;
    text-align: left;
}
.push2{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: white;
    text-align: center;
    
}
.theProject1{
    margin-left: 25%;
    margin-right: 25%;  
}
.push2_text{
    padding:2%; 
    text-align: left;
}
.valueProp h3 h4{
    text-align: left;
}
/* .summary{
    background-color:gray;
    padding:2%;
} */
.questions{
    background-color:whitesmoke;
    padding:2%;
}
.researchPlan1{
    background-color:white;
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 25%;
    padding-right: 25%;
}
/* .researchPlan_text{
    text-align: left;
    margin-left: 20%;
    margin-right: 20%; 
} */
.tools{
    background-color:lightgrey;
    text-align: center;
    padding:2%;
}
.gridtextbox10{
    padding-top: 1%;
    font-size:  22px;
    margin-left: 40%;
    
    }
.questions_text{
    text-align: left;
    padding-left: 10%;
}
.gridtextbox2{
    align-items: center;
        padding-right: 10% !important;
        padding-top: 1%;
    }
.gridtextbox3{
    align-items: center;
        padding-left:10% !important;
        padding-top: 1%;
    }
.green{
    max-height: 300px;
    width: auto;
    padding:1%;
    
}
.green:hover{
   
    -webkit-animation-name: spin;
   
            animation-name: spin;
    -webkit-animation-duration: 5000ms;
            animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}
@-webkit-keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}
.greensContainer{
   padding-left: 10%;
}

.findings77{
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: whitesmoke;
   
}
.findings_text{
    text-align: left;
    margin-left: 25%;
    margin-right: 25%; 
}
.now10{
    align-items: center;
    padding-top:2%;
    background-color: white;
    padding-left: 10%;
    padding-right: 10%
}
.now_text{
    text-align: left;
    padding-left: 10% !important;
}
.gridtextbox7{
    align-items: center;
    padding-top: 1%;
    margin-left: 25%;
}
.gameContainer{
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
}
.game{
   height:400px;
    width: auto;
    padding:1%;

}
#go:hover{
    background-color: silver;
}
.looks{
    padding-top: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    background-color: white;

}
.care{
   width: 100%;
   height: auto;
}

.last{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/plantss.5208b7e4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    text-align: center;
    padding-top: 4%;
}

.final{
    align-content: right;
    padding-top: 3rem;

}
.final_text{
    padding-left: 15%;
}
.wonder{
    background-color:whitesmoke;
  
}
.keep{
    color: white;
}
#projects_button{
    margin: 2%;
    background-color: #e7e7e7; color: black;
    font-size: 20px;
    border-radius: 4px;
    padding:1%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

}
#projects_button:hover{
    background-color: black; color: #e7e7e7;
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  
}
.planta{
    width: 1005;
    height: 80vh;
    position: relative;
    padding-left: 20%;
}
.video{
    object-fit: cover;
    
}

.hero_image2{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/ellipse.77a86e79.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.case2{
    display:flex;
    flex-direction: column;
    margin-top: 3%;

}
.bigger{
    padding:0px;
    margin: 0px;
    width: 100% !important;
   
    
}
.hero_text4{
    color:white;
    font-weight: bold;
    text-align: center;
    padding:4%;
    margin-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 3%;
}
 .theProject2{
    background-color: whitesmoke;
    text-align: left;
    padding:2%;
    padding-left: 25%;
    padding-right: 25%;
}  
.valueProp2{
    color:black;
    text-align: left;
    padding:2%; 
   
}
.user{
    height: 300px;
}
.summary{
    background-color:gray;
    text-align:left;
    padding:2%;
}
.questions2{
    background-color:whitesmoke;
    text-align: left;
    padding:2%;
}
.researchPlan2{
    text-align: left;
    padding:2%;
    padding-right: 4%;
}
.tools{
    background-color:lightgrey;
    text-align: center;
    padding:2%;
}
.grid-container8{
    display: grid;
    padding-top: 2%;
    align-items: left;
    font-size:  22px; 
    }
.gridtextbox7{
        align-items: right;
        /* padding-right: 10% !important; */
        padding-top: 1%;
    }
.gridtextbox3{
        align-items: left;
        padding-top: 1%;
    }
.red1{
    display: block;
    max-height: 500px;
    max-width: 100%;
   object-fit: contain !important;
    padding:1%;
    
}
.li{
    list-style-type: none;
} 

.findings2{
    text-align: center;
    padding:2%;
    background-color: whitesmoke;
}
.now2{
    text-align: center;
    padding:2%;
   max-height: 900px;
    background-color: whitesmoke;
}
.gridtextbox4{
    align-items: right;
    padding-top: 1%;
}
.style{
    height: 20%;
    width: 80%;
}
#go:hover{
    background-color: silver;
}
.thoughts{
    text-align: center;
    padding:2%;
}  
.mood{
    height: 300px;
}
.site{
    height: 500px;
    width: 400px;
}
.before{
    height: 300px;
    padding:20px;
}
.after{
    height: 300px;
    padding:20px;
}
.last2{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/ellipse.77a86e79.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    text-align: center;
    padding-top: 4%;
}
#projects_button2{
    margin: 2%;
    background-color: #e7e7e7; color: black;
    font-size: 20px;
    border-radius: 4px;
    padding:1%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

}
#projects_button2:hover{
    background-color: black; color: #e7e7e7;
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    box-shadow: 0px 15px 20px 	rgb(255,215,0);
  
}
.found{
    text-align: left;
    padding:2%;
    /* padding-left: 25%;
    padding-right: 25%; */
}
.now_text{
    display: inline-block;
    text-align: left;
    padding:2%;
}
.gridtextbox7{
    text-align: left;
    padding:2%;
}
.yep{
    padding-right: 5%;
}
@media only screen and (max-width: 500px){
    .mood{
        flex-direction: column;
        width:100%;
    }
    .style{
        flex-direction: column;
        width:100%;
    }
    .hero_text4{
        margin-top: 15%;
    }
   
}

.hero_image3{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/world.d996417c.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.case3{
    display:flex;
    flex-direction: column;
    margin-top: 5%;

}
.bigger{
    padding:0px;
    margin: 0px;
    width: 100% !important;  
}
.hero_text8{
    color:white;
    font-weight: bold;
    text-align: center;
    padding-top:5%;
    padding-bottom: 2%;
}
 .theProject3{
    background-color: whitesmoke;
    text-align: left;
    padding:2%;
    padding-left: 25%;
    padding-right: 25%;
}  
.valueProp{
    color:black;
    text-align: left;
    padding:2%; 
   
}
.user{
    height: 300px;
}
.summary{
    background-color:gray;
    text-align: center;
    padding:2%;
}
.questions5{
    text-align: center;
    padding:2%;
}
.researchPlan3{
    text-align: left;
    padding:2%;
    padding-left: 10%;
    padding-right: 10%;
}
.realizations9{
    text-align: left;
    padding-left: 2% !important;
    padding-right: 2% !important;
    

}
.tools{
    background-color:lightgrey;
    text-align: center;
    padding:2%;
}
.gridtextbox1{
    padding-top: 1%;
    align-items: left;
    text-align: left;
    padding-left: 4% !important;
    font-size:  22px;
    
    }
.gridtextbox23{
    padding-left: 2%;
        align-items: right;
        padding-right: 10% ;
        padding-top: 1%;
    }
.gridtextbox3{
        align-items: right;
        text-align: left;
        padding-left:5% !important;
        padding-top: 1%;
    }
.red{
    max-height: 500px;
    width: auto;
    padding:1%;
    
}
.li{
    list-style-type: none;
} 

.findings44{
    margin-top: 3rem;
    padding:2%;
   margin-left: 10%;
   margin-right: 10%;
    
}
.now{
    text-align: center;
    padding:2%;
   max-height: 900px;
    background-color: whitesmoke;
}
.gridtextbox9 h5{
    align-items: right;
    text-align: left;
    padding-top: 1%;
    padding-left: 25%;
    padding-right: 25%;
}
/* .game{
    height: 20%;
    width: auto;
    padding:1%;
} */
#go:hover{
    background-color: silver;
}
.thoughts{
    text-align: center;
    padding:2%;
}  
.last3{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/world.d996417c.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    text-align: center;
    padding-top: 4%;
}
#projects_button3{
    margin: 2%;
    background-color: #e7e7e7; color: black;
    font-size: 20px;
    border-radius: 4px;
    padding:1%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

}
#projects_button3:hover{
    background-color: black; color: #e7e7e7;
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    box-shadow: 0px 15px 20px 	rgb(139,69,19);
  
}
.keep3{
    color: white;
}
.user5{
    height: 45%;
    max-width: 100%;
    padding:1%;
    align-content: center;
}
.nos{
    text-align: center;
    padding:2%;
   max-height: 900px;
    background-color: whitesmoke; 
}
.row22{
    padding: 2%;
   margin-left: 10%;
   margin-right: 10%;
   margin-top: 3rem;
   
}
.long{
    background-color: whitesmoke;
}
.gamey{
    height: 45%;
    width: 100%;
}
.pause{
    margin-top: 3rem;;
}

.spike{
    width: 1005;
    height: 80vh;
    position: relative;
}
.video{
    object-fit: cover;
}
.char{
    background-color: whitesmoke;
}
.case4{
    display:flex;
    flex-direction: column;
}
.theProject4{
    padding:3%;
    padding-left: 21%;
    padding-right: 21%;
    text-align: left;
}
.hero_text4{
    color:white;
    font-weight: bold;
    text-align: center;
    padding:2%;
}
.hero_image4{
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../images/projectimages/v2.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.last4{
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../images/projectimages/v2.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    text-align: center;
    padding-top: 4%;
    background-color: red;
}
#projects_button4{
    margin: 2%;
    background-color: #e7e7e7; color: black;
    font-size: 20px;
    border-radius: 4px;
    padding:1%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

}
#projects_button4:hover{
    background-color: black; color: #e7e7e7;
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    box-shadow: 0px 15px 20px 	rgb(255,0,0);
  
}
.keep4{
    color: white;
}
.now4{
    display: flex;
    align-items: center;
    padding: 2%;
    margin-top: 3rem;
}
.gridtextbox4{
    text-align: left;

}
.additions{
    padding: 2%;
    margin-top: 3rem;
}

.vinsight{
    background-color: whitesmoke;
    height: 600px;
    padding: 2%;
    margin-top: 3rem;
}
.like{
    height: 25%;
    width: auto;
}
.smile{
    height: 75%;
    max-width: 100%;
}
.questions99{
    padding: 2%;
    margin-top: 3rem;
  
}
.userp{
    padding: 2%;
    margin-top: 3rem;
    background-color: whitesmoke; 
}
.child{
    width:65%;
    height: auto;
    padding: 5%;
}
