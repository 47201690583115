.overlay{
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
  }
  
  .gallery{
    display: inline-block; 
   
  }
  
  .picMe{
  
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-bottom: 240px;
    height: 500px;
      width: 500px;
      border: 3px solid #444444;
     
  }
  .string{
    display: flex;
      align-items: center;
      justify-content: center;
      color: whitesmoke;
      z-index: 1;
      font-size: 44px;
      position: absolute; 
  }
  
  
  .body{
   position: relative;
  }
  
  .particles-bg-canvas-self{
    height: 100% !important;
    position:fixed;
    width:100%;
    z-index:-1;
    /* opacity: 60%; */
  
  }
  
  
  
  
  