
.name{
    color:whitesmoke;
    display: inline-block;
    z-index: 40;
    top:27%;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
.byes{
    /* position: fixed; */
    text-align: center;
    width: 100%;
    justify-content: center;
    background-repeat: no-repeat;
}






