.whitespace{
    padding-top: 15%;
    text-align: center;
}
.white{
    text-align: center;
}
@keyframes slide{
    0% {
        left: 100px;
        top: 0;
      }
      25% {
        left: 300px;
        top: 0;
      }
      50% {
        left: 600px;
        top: 100px;
      }
      75%{
          left:800px;
          top: 100px;
      }
      100% {
        left: 1500px;
        top: 0;
      }
    }
    
.duckyContainer{
    position: relative;
    border-radius: 100px;
    height: 400px;
}
.duckyContainer:hover .ducky{
    animation: slide 3s linear;
}
.ducky{
    height:100px;
    width: auto;
    position: absolute;
}

/* .ducky:hover{
    animation: fly 60s linear infinite;
    pointer-events: none !important;
top: 0;
left: 0;
transform: translateX(-120%) translateY(-120%) rotateZ(0);
position: fixed;
animation-delay: 25s;
z-index: 999999;
}
@keyframes fly {

	98.001%, 0% {
                display: block;
		transform: translateX(-200%) translateY(100vh) rotateZ(0deg)
	}

	15% {
		transform: translateX(100vw) translateY(-100%) rotateZ(180deg)
	}

	15.001%, 18% {
		transform: translateX(100vw) translateY(-30%) rotateZ(0deg)
	}

	40% {
		transform: translateX(-200%) translateY(3vh) rotateZ(-180deg)
	}

	40.001%, 43% {
		transform: translateX(-200%) translateY(-100%) rotateZ(-180deg)
	}

	65% {
		transform: translateX(100vw) translateY(50vh) rotateZ(0deg)
	}

	65.001%, 68% {
		transform: translateX(20vw) translateY(-200%) rotateZ(180deg)
	}

	95% {
		transform: translateX(10vw) translateY(100vh) rotateZ(0deg)
	}
} */