.navi_container{
    background-color: var(--nav-color);
    color:white;
    /* padding-bottom: 100px; */
  }

  .navbar-light .navbar-nav .nav-link{
      color: var(--font-color)!important;
  }
  .navbar-light .navbar-brand{
    color: var(--font-color)!important;
}
.navbar-light .navbar-toggler-icon{
    filter:invert(1);
}

.links a:hover{
    background-color:#A9A9A9!important;
    color:#070606;
}
.logo{

    height:60px;
    width: 60px;
    transition: all;
}
.logo:hover{
    /* background-image: url("../../images/blck-wsolve5.png"); */
    height:50px;
    width: 50px;
    transform:scale(1.5,1.5);
    margin-right: 10px;

   
}
.small{
    padding-left: 15px;
}
.img{
    background-color:var(--nav-color) ;
    height:50px;
    width: 50px;
}
.links{
    margin: 0px 15px;
}
.nav_brand{
   color:whitesmoke; 

}.nav_brand:hover{
   color:#A9A9A9!important;
 }
