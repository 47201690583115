.about55{
    display:flex;
    flex-direction: column;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-color: gainsboro;
    /* opacity:85%; */
    opacity: .8;
    color:black !important; 
    font-weight: bold;
    
    
}
.res{
    margin-top: 3rem;
}

.gridtextbox1{
align-items: left;
/* padding-left: 10% !important; */
font-size:  22px;

}
.gridtextbox2{
    align-items: right;
    /* padding-right: 10% !important; */
}


.info{
    align-content: left;
}
.resumeShape{
 z-index: 20;
    padding: 0,10%;
    width: max-content;
    margin: 3rem;

}
@media only screen and (max-width: 500px){
    .about55{
        flex-direction: column;
        padding: 5%;
    }
    .resumeShape{
        padding: 5%;
        width: fit-content;
    }
}

.text {
	font-weight: bold;
}
.resLink{
    justify-content: center !important;
    text-align: center;
    padding-top:5%;
    margin-bottom: 0;

}
