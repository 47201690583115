
.spike{
    width: 1005;
    height: 80vh;
    position: relative;
}
.video{
    object-fit: cover;
}
.char{
    background-color: whitesmoke;
}
.case4{
    display:flex;
    flex-direction: column;
}
.theProject4{
    padding:3%;
    padding-left: 21%;
    padding-right: 21%;
    text-align: left;
}
.hero_text4{
    color:white;
    font-weight: bold;
    text-align: center;
    padding:2%;
}
.hero_image4{
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../images/projectimages/v2.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.last4{
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../images/projectimages/v2.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    text-align: center;
    padding-top: 4%;
    background-color: red;
}
#projects_button4{
    margin: 2%;
    background-color: #e7e7e7; color: black;
    font-size: 20px;
    border-radius: 4px;
    padding:1%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

}
#projects_button4:hover{
    background-color: black; color: #e7e7e7;
    transform: translateY(-7px);
    box-shadow: 0px 15px 20px 	rgb(255,0,0);
  
}
.keep4{
    color: white;
}
.now4{
    display: flex;
    align-items: center;
    padding: 2%;
    margin-top: 3rem;
}
.gridtextbox4{
    text-align: left;

}
.additions{
    padding: 2%;
    margin-top: 3rem;
}

.vinsight{
    background-color: whitesmoke;
    height: 600px;
    padding: 2%;
    margin-top: 3rem;
}
.like{
    height: 25%;
    width: auto;
}
.smile{
    height: 75%;
    max-width: 100%;
}
.questions99{
    padding: 2%;
    margin-top: 3rem;
  
}
.userp{
    padding: 2%;
    margin-top: 3rem;
    background-color: whitesmoke; 
}
.child{
    width:65%;
    height: auto;
    padding: 5%;
}