

.work{
  display: flex;
}

.projects{
  text-align: center;
}
.hi{
  text-align: center;
}

.img_g{

  object-fit: cover;
  height: 300px ;
  margin-top: -20%;
  width: 100%;
  border-radius: 20px;

}
.img_g1{

  object-fit: cover;
  height: 300px ;
  margin-top: -20% ;
  width: 100%;
  border-radius: 20px ;

}
.img_g1:hover{
  object-fit: cover;
  height: 300px ;
  margin-top: -20%;
  width: 100%;
  border-radius: 20px!important;;
}

.one{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: #e5e5e5!important;
  width: 20rem !important;
  height: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px !important;
  margin-top: 10rem;
  cursor: 'pointer'
}
/* .card2{
  height: 500px;
} */
.card-text{
  list-style-type:square;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.cardContainer{

  margin-bottom: 3rem;
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height:500px;
   
}

#git{
  color:black !important; 
  margin-bottom: 3rem;
}
#case{
  font-size: 1.2rem;
  border-radius: 10px;
  padding: 11px 1rem;
  /* margin: 0 0.5rem ; */
  width: 7.5rem;

}
.container-fluid{
  margin-top: 3rem;
  /* margin-bottom: 3rem; */
}
.card-title{
  margin: 0;
  margin-top: 1rem;
}
.across{
  display: flex;

}

