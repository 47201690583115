.me{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 3rem; */
    color:black;
}
.period{
 margin-top: 3rem;
}


